import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PCTimeline from "../../../components/timeline/pc-timeline";
import PCTimelineItem from "../../../components/timeline/pc-timeline-item";
export const _frontmatter = {
  "path": "/hello-gatsby",
  "date": "2020-11-07T00:00:00.000Z",
  "title": "Updating site to use Gatsby.js"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Website Update!`}</h1>
    <p>{`It is time to rewrite my personal website using different technologies! Over
the years, I have switched frameworks and/or libraries for my quest to become a better
software engineer. The driving reason why I do this every few years is so I
can learn new technology or apply what I have already learned from my professional work experience.
Iterating over this site has helped increase my knowledge in several technologies as listed below:`}</p>
    <h2>{`Tech Stack Timeline`}</h2>
    <PCTimeline mdxType="PCTimeline">
  <PCTimelineItem name="2009" tags={["PHP", "Html", "JavaScript", "MySQL", "JQuery"]} mdxType="PCTimelineItem">
    For the first implemenation of this website I chose PHP. I had some
    experience writing Classic ASP and ASP.NET WebForms applications but, for a
    personal site, I wanted to pay less for my hosting and try something I was
    not familiar with.
  </PCTimelineItem>
  <PCTimelineItem name="2014" tags={["C#", "Asp.NET MVC", "Asp.NET Web API", "Razor", "JavaScript", "Microsoft SQL Server", "JQuery", "WebGL", "Bootstrap"]} mdxType="PCTimelineItem">
    This was a big move to start using .NET for my personal site and was able to
    spin up other side projects.
  </PCTimelineItem>
  <PCTimelineItem name="2017" tags={["GitHub Pages", "Jekyll", "Ruby", "JavaScript", "JQuery", "Bootstrap"]} mdxType="PCTimelineItem">
    I made the move to GitHub Pages since it was a free host and I didn't need
    the complexity that came with my .NET API and SQL Server.
  </PCTimelineItem>
  <PCTimelineItem name="2020" tags={["React", "Typescript", "Gatsby.js", "Azure", "Material-UI"]} mdxType="PCTimelineItem">
    Currently using these frameworks/libaries.
  </PCTimelineItem>
    </PCTimeline>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      